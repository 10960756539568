import { CommonModule } from "@angular/common";
import {
  Component,
  contentChild,
  Input,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { emptyText } from "./shared/const/empty-state.const";

@Component({
  standalone: true,
  selector: "app-empty-state",
  imports: [CommonModule],
  templateUrl: "./empty-state.component.html",
  styles: [
    `
      .empty-state {
        padding-top: 5vh;
        padding-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      h3 {
        padding-top: 1rem;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #949494;
      }

      .empty-state.big {
        padding-top: 0;
        margin-top: -10rem;
      }

      .big h3 {
        font-size: 20px;
        font-weight: 500;
      }

      .big p {
        font-size: 1rem;
        margin-top: 0.5rem;
      }

      .set {
        padding-top: 0;
      }
    `,
  ],
})
export class EmptyStateComponent implements OnInit {
  @Input()
  public icon: string | undefined = iconLib.desktop.src;

  @Input()
  public title: string | undefined;

  @Input()
  public titleClass: string = "";

  @Input()
  public subtitle: string | undefined;

  @Input()
  public subtitleHTML: string | undefined;

  @Input()
  public subClass: string = "";

  @Input()
  public type: string = "";

  @Input()
  public big: boolean = false;

  @Input()
  public isTitle: boolean = true;

  @Input()
  public category: "house" | "association" | null = null;

  @Input()
  public set: boolean = false;

  public emptyText = emptyText;

  readonly subtitleRef = contentChild("subTemplate", { read: TemplateRef });

  ngOnInit(): void {
    if (this.category === "house") {
      this.icon = iconLib.house.src;
      this.title = "Result not found";
    }
  }
}
